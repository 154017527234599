@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
    width: 0.5em;
    /* Set the width of the scrollbar (adjust as needed) */
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    /* Set the color of the scrollbar thumb */
}

body {
    font-family: Rubik;
}

@font-face {
    font-family: "GilroyRegular";
    src: local("GilroyRegular"),
        url(./assets/fonts/Gilroy-ExtraBold.otf) format("opentype");
    font-weight: normal;
}

.head {
    font-family: "GilroyRegular";
}


.paginationBttns {
    display: flex;
    list-style: none;
    justify-content: center;
}

.paginationBttns li {
    display: none;
}

.paginationBttns .previous, .paginationBttns .next {
    display: block;
}


.paginationBttns .disabled a {
    background-color: #DADADA;
}

.paginationBttns a {
    padding: 3px 6px;
    margin: 8px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;
    border: 1px solid #DADADA;
}

.paginationActive a {
    background-color: #FA8151;
}

.sub-navigation-wrapper {
    display: flex;
    flex-wrap: wrap;
    background: #FFFCF9;
}

.sub-navigation-wrapper.white {
    display: flex;
    flex-wrap: wrap;
    background: white;
}

.sub-navigation-wrapper p {
    color: #727272;
    font-size: 16px;
    font-weight: 400;
    padding: 0px 15px 10px 15px;
    margin: 10px 20px 0px 0;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: capitalize;
}

.sub-navigation-wrapper p.active-item {
    color: #FA8151;
    border-bottom: 5px solid #FA8151;
    font-weight: 500;
    padding: 0px 15px 10px 15px;
}

input[type='range'] {
    -webkit-appearance: none;
    height: 7px;
    background: rgba(52, 103, 113, 0.32);
    border-radius: 5px;
    background-image: rgba(52, 103, 113, 0.32);
    background-repeat: no-repeat;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #FA8151;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
}

.table-wrapper {
    background-color: var(--white);
    padding: 10px 0;
    overflow-x: scroll;
}

.table-wrapper.dark-head {
    padding: 0 0 20px 0;
}

.table-wrapper::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.table-wrapper::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 20px;
}

.table-wrapper table {
    white-space: nowrap;
    border-collapse: collapse;
    width: 100%;
    font-weight: 400;
    font-size: 13px;
}

.table-wrapper table th {
    text-align: start;
    font-weight: 700;
    color: #000;
    background-color: #F5F5F5;
    padding: 10px 10px;

}

.table-wrapper.dark-head table th {
    background-color: #fcfcfc;
    color: #000;
    font-weight: 500;
}

.table-wrapper.white-head table th {
    background-color: white;
    border-bottom: 1px solid grey;
    color: #000;
    font-weight: 500;
}

.table-wrapper.dark-head table th:first-child {
    padding: 15px 20px;
}

.table-wrapper table td {
    padding-top: 12px;
    /* vertical-align: text-top; */
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.239);
    padding: 10px 10px;
}


.table-wrapper.dark-head table td:first-child {
    padding-left: 20px;
}

@media (max-width: 80rem) {
    .table-wrapper table.longer-table {
        width: 900px;
    }
}

@media (max-width: 50rem) {
    .table-wrapper table.long-table {
        width: 700px;
    }
}

@media (max-width: 40rem) {
    .table-wrapper table {
        width: 700px;
    }

    .table-wrapper table.long-table {
        width: 700px;
    }

    .table-wrapper table.longer-table {
        width: 900px;
    }
}

@media (max-width: 30rem) {
    .table-wrapper table {
        width: 600px;
    }

    .table-wrapper table.long-table {
        width: 700px;
    }

    .table-wrapper table.longer-table {
        width: 900px;
    }
}


